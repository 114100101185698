import request from './handler'

const country = () => request.get('/address/countries')
const provinceById = (countryId) => request.get(`/address/provinces/${countryId}`)
const cityById = (provinceId) => request.get(`/address/cities/${provinceId}`)
const districtById = (cityId) => request.get(`/address/districts/${cityId}`)
const province = () => request.get('/address/provinces')
const city = () => request.get('/address/cities')
const district = () => request.get('/address/districts')

export default {
  country,
  province,
  city,
  district,
  provinceById,
  cityById,
  districtById
}
