import request from './handler'
const save = async (projectId, projectCode, form) => {
  const { data } = await request.post(`/project-files?projectId=${projectId}&projectCode=${projectCode}`, form)
  return data
}

const get = async (projectId, projectCode) => {
  const { data } = await request.get(`/project-files?projectId=${projectId}&projectCode=${projectCode}`)
  return data
}

const edit = async (projectId, projectCode, form) => {
  const { data } = await request.put(`/project-files?projectId=${projectId}&projectCode=${projectCode}`, form)
  return data
}

const download = filename => request.get(`/project-files/download/${filename}`, { responseType: 'blob' })
export default {
  save,
  get,
  edit,
  download
}
