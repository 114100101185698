import request from './handler'

const me = () => request.get('/users/me')
const getListUsers = params => request.get('/users', { params })
const get = username => request.get(`/users/${username}`)
const list = async form => {
  const { data } = await request.post('/users', form)
  return data
}
const save = form => request.post('/users', form)
const put = (id, form) => request.put(`/users/${id}`, form)
const remove = id => request.delete(`/users?id=${id}`)
const changePassword = (id, form) => request.put(`/users/change-password/${id}`, form)
export default {
  me,
  get,
  list,
  save,
  put,
  remove,
  getListUsers,
  changePassword
}
