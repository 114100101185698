import request from './handler'

const list = async (id, params) => {
  const { data } = await request.get(`/employees/${id}/training-list`, { params })
  return data
}

const providerName = async () => {
  const { data } = await request.get('/provider')
  return data
}
const trainingContext = async () => {
  const { data } = await request.get('/training-context')
  return data
}
const get = (id, params) => request.get(`/employee-training/${id}`, { params })
const getById = id => request.get(`/employee-training/${id}`)
const put = (id, form) => request.put(`/employee-training?id=${id}`, form)
const post = form => request.post('/employee-training', form)
const statusCertificate = (id, active) => request.get(`/employee-training/${id}/${active}`)
const erased = async (id) => {
  const { data } = await request.delete(`/employee-training?id=${id}`)
  return data
}

export default {
  providerName,
  trainingContext,
  get,
  list,
  getById,
  put,
  post,
  statusCertificate,
  erased
}
