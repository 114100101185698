import request from './handler'

const status = () => request.get('/status')
const technologies = () => request.get('/technologies')
const devRoles = () => request.get('/development-roles')
const save = form => request.post('/projects/details', form)
const clients = form => request.get('/clients/search', form)
const saveClient = form => request.post('/clients', form)
const get = id => request.get(`/projects/details?id=${id}`)
const shortInfo = id => request.get(`/projects/short-info?id=${id}`)
const update = form => request.put('/projects/details', form)
export default {
  status,
  technologies,
  save,
  saveClient,
  clients,
  devRoles,
  get,
  shortInfo,
  update
}
