import request from './handler'

const list = async params => {
  const { data } = await request.get('/groups', { params })
  return data
}

const permissions = isDefault => request.get(`/groups/permissions?isDefault=${isDefault}`)

const submitRole = form => request.post('/groups', form)
const getRole = id => request.get(`/groups/details?id=${id}`)
const updateRole = (id, form) => request.put(`/groups/${id}`, form)
const deleteRole = id => request.delete(`/groups?id=${id}`)

export default {
  list,
  submitRole,
  getRole,
  updateRole,
  deleteRole,
  permissions
}
