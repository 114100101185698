import request from './handler'

const list = async params => {
  const { data } = await request.get('/taxes', { params })
  return data
}

export default {
  list
}
