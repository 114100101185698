import request from './handler'

const login = form => request.post('/auth/login', form)
const logout = () => request.post('/auth/logout')
const reqResetPassword = form => request.post('/account/req-reset-password', form)
const resetPassword = form => request.post('/account/reset-password', form)

export default {
  login,
  logout,
  reqResetPassword,
  resetPassword
}
