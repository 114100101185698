import request from './handler'

const listProject = () => request.get('/dashboard/project')
const listRecentProject = () => request.get('/dashboard/recent-project')
const listRecentInvoice = () => request.get('/dashboard/recent-invoice')

export default {
  listProject,
  listRecentProject,
  listRecentInvoice
}
