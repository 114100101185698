import request from './handler'

const list = async params => {
  const { data } = await request.get('/invoices', { params })
  return data
}

const get = async (invoiceId) => {
  const { data } = await request.get(`/invoices/detail?id=${invoiceId}`)
  return data
}

const status = () => request.get('/invoices/status-invoices')
const save = form => request.post('/invoices', form)
const detail = async (id) => {
  const { data } = await request.get(`/invoices/detail?id=${id}`)
  return data
}
const deleteInvoice = async id => {
  const { data } = await request.delete(`/invoices?id=${id}`)
  return data
}
const send = form => request.put('/invoices/send-invoice', form)
const setPaid = form => request.put('/invoices/set-paid', form)
const downloadWord = (id) => request.get(`/invoices/download-word?id=${id}`, { responseType: 'blob' })
const downloadPdf = (id) => request.get(`/invoices/download?id=${id}`, { responseType: 'blob' })

export default {
  list,
  get,
  status,
  save,
  detail,
  deleteInvoice,
  send,
  setPaid,
  downloadWord,
  downloadPdf
}
