import request from './handler'

const list = async params => {
  const { data } = await request.get('/profit-projects', { params })
  return data
}
const profitYear = () => request.get('profit-projects/years')

export default {
  list,
  profitYear
}
