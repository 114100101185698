import request from './handler'

const framework = async () => {
  const { data } = await request.get('/framework?Sorts=Name')
  return data
}
const operatingSystem = async () => {
  const { data } = await request.get('/operating-system?Sorts=Name')
  return data
}
const platform = async () => {
  const { data } = await request.get('/platform?Sorts=Name')
  return data
}
const providerName = async () => {
  const { data } = await request.get('/provider?Sorts=Name')
  return data
}
const database = async () => {
  const { data } = await request.get('/database?Sorts=Name')
  return data
}
const devTools = async () => {
  const { data } = await request.get('/development-tools?Sorts=Name')
  return data
}
const programmingLanguage = async () => {
  const { data } = await request.get('/programming-language?Sorts=Name')
  return data
}
export default {
  framework,
  operatingSystem,
  platform,
  providerName,
  database,
  devTools,
  programmingLanguage
}
