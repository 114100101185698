import request from './handler'

const byProjects = async params => {
  const { data } = await request.get('/timeline/by-projects', { params })
  return data
}

const byEmployees = async params => {
  const { data } = await request.get('/timeline/by-employees', { params })
  return data
}

export default {
  byProjects,
  byEmployees
}
