import request from './handler'

const get = async () => {
  const { data } = await request.get('/groups')
  return data
}

export default {
  get
}
