import request from './handler'

const getAvatar = id => request.get(`/employees/url-avatar?id=${id}`)
const showAvatar = id => request.get(`/employees/image-avatar/${id}`)
const postAvatar = form => request.post(
  '/employees/avatar',
  form,
  {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
)
const deleteAvatar = id => request.delete(`/employees/avatar/${id}`)
export default {
  getAvatar,
  showAvatar,
  postAvatar,
  deleteAvatar
}
