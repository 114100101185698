import request from './handler'

const list = (params) => request.get('/developers', { params })
const get = (id) => request.get(`/developers/${id}`)
const remove = (id) => request.delete(`/developers/${id}`)
const add = (form) => request.post('/developers', form)
const update = (form, id) => request.put(`/developers/${id}`, form)

export default {
  list,
  get,
  remove,
  add,
  update
}
