import request from './handler'

const list = async params => {
  const { data } = await request.get('/employees', { params })
  return data
}
const get = (id, params) => request.get(`/employees/${id}`, { params })
const getById = id => request.get(`/employees/${id}`)
const getCVData = id => request.get(`/employees/${id}/cv-employee`)
const getAvatar = id => request.get(`/employees/avatar/${id}`)
const put = (form, params) => request.put('/employees', form, { params })
const putList = (id, form) => request.put(`/employees?id=${id}`, form)
const post = form => request.post('/employees', form)
const changeStatus = (id, params) => request.put(`employees/status/${id}`, null, { params })
const changeAvatar = (formData) => request.post('employees/avatar', formData)
const language = async () => {
  const { data } = await request.get('/language')
  return data
}
const bank = async () => {
  const { data } = await request.get('/banks')
  return data
}
const role = async () => {
  const { data } = await request.get('/employee-roles')
  return data
}

export default {
  list,
  get,
  getById,
  getAvatar,
  put,
  putList,
  post,
  changeStatus,
  language,
  bank,
  role,
  changeAvatar,
  getCVData
}
