import request from './handler'

const dayOfSprint = () => request.get('/development-info/day-of-sprint')
const holidays = (start, end) => request.get(`/development-info/holidays?start=${start}&end=${end}`)
const save = form => request.post('/development-info', form)
const get = async (id) => {
  const { data } = await request.get(`/development-info?id=${id}`)
  return data
}
const getSprint = async (id) => {
  const { data } = await request.get(`/development-info/sprints?parameter=${id}`)
  return data
}
const edit = form => request.put('/development-info', form)
const saveExtendDays = form => request.post('/development-info/extend-days', form)
const editSprint = (id, form) => request.put(`/development-info/sprint/${id}`, form)
export default {
  dayOfSprint,
  holidays,
  save,
  get,
  edit,
  getSprint,
  saveExtendDays,
  editSprint
}
