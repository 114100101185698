import request from './handler'

const list = async () => {
  const { data } = await request.get('/religion?Page=1&PageSize=10')
  return data
}
const get = () => request.get('/religion')
export default {
  list,
  get
}
