import request from './handler'
const getSummary = id => request.get(`/project-expenses/summary?id=${id}`)
const listExpense = async params => {
  const { data } = await request.get('/project-expenses', { params })
  return data
}
const saveExpense = form => request.post('/project-expenses', form)
const editExpense = (id, formData) => request.put(`/project-expenses/${id}`, formData, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})
const deleteExpense = async id => {
  const { data } = await request.delete(`/project-expenses?id=${id}`)
  return data
}
const viewExpense = id => request.get(`/project-expenses/expense?id=${id}`)
const detail = id => request.get(`/project-expenses/expense?id=${id}`)
const download = id => request.get(`/project-expenses/file-bill/${id}`, { responseType: 'blob' })
const exportExpense = (params) => request.get('/project-expenses/download-excel/', { params, responseType: 'blob' })

export default {
  getSummary,
  listExpense,
  saveExpense,
  editExpense,
  deleteExpense,
  viewExpense,
  detail,
  download,
  exportExpense
}
