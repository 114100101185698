import request from './handler'

const list = async params => {
  const { data } = await request.get('/budgets/budget-activities', { params })
  return data
}
const type = async params => {
  const { data } = await request.get('/budgets/budget-types', { params })
  return data
}
const budgetingList = async params => {
  const { data } = await request.get('/budgets', { params })
  return data
}

const viewBudget = id => request.get(`/budgets/${id}`)
const downloadExcel = (id) => request.get(`/budgets/download-excel?id=${id}`, { responseType: 'blob' })
const downloadPdf = (id) => request.get(`/budgets/download-pdf?id=${id}`, { responseType: 'blob' })
const save = form => request.post('/budgets/budget-details', form)
const budgetDefault = () => request.get('/budgets/default')
const submitType = typeName => request.post('/budgets/budget-types', typeName)
const getType = id => request.get(`/budgets/budget-types/${id}`)
const updateType = (id, form) => request.put(`/budgets/budget-types/${id}`, form)
const deleteType = id => request.delete(`/budgets/budget-types?id=${id}`)
const submitActivity = form => request.post('/budgets/budget-activities', form)
const getActivity = id => request.get(`/budgets/budget-activities/${id}`)
const updateActivity = (id, form) => request.put(`/budgets/budget-activities/${id}`, form)
const deleteActivity = id => request.delete(`/budgets/budget-activities?id=${id}`)

export default {
  list,
  type,
  budgetingList,
  viewBudget,
  downloadExcel,
  downloadPdf,
  save,
  budgetDefault,
  submitType,
  getType,
  updateType,
  deleteType,
  submitActivity,
  getActivity,
  updateActivity,
  deleteActivity
}
