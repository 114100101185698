import request from './handler'
const save = form => request.post('/proposals', form)
const get = async (projectId) => {
  const { data } = await request.get(`/proposals?projectId=${projectId}`)
  return data
}
const download = filename => request.get(`/proposals/download?fileName=${filename}`, { responseType: 'blob' })
const getHistory = async (projectId) => {
  const { data } = await request.get(`/proposals/histories?projectId=${projectId}`)
  return data
}
export default {
  save,
  get,
  download,
  getHistory
}
