import request from './handler'

const getDevelopmentRole = (params) => request.get('/development-roles/roles', { params })

const deleteRole = (id) => request.delete(`/development-roles?id=${id}`)
const addRole = (form) => request.post('/development-roles', form)
const editRole = (id, form) => request.put(`/development-roles?id=${id}`, form)

export default {
  getDevelopmentRole,
  deleteRole,
  addRole,
  editRole
}
