import request from './handler'

const tax = () => request.get('/taxes')
const save = form => request.post('/payment-terms', form)
const get = id => request.get(`/payment-terms?projectId=${id}`)
const getUnpaid = id => request.get(`/payment-terms/unpaid?projectId=${id}`)

export default {
  tax,
  save,
  get,
  getUnpaid
}
