import request from './handler'

const list = async params => {
  const { data } = await request.get('/projects', { params })
  return data
}

const get = async (projectId) => {
  const { data } = await request.get(`/projects/short-info?id=${projectId}`)
  return data
}

const getDetail = async (projectId) => {
  const { data } = await request.get(`/projects/details?id=${projectId}`)
  return data
}

const status = () => request.get('/status')

const listProject = () => request.get('projects/list-projects')

export default {
  list,
  get,
  getDetail,
  status,
  listProject
}
