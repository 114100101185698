import auth from '@/api/auth'
import user from '@/api/user'
import role from './role'
import projectDetail from './projectDetail'
import proposal from './proposal'
import paymentTerm from './paymentTerm'
import developmentInfo from './developmentInfo'
import files from './files'
import developmentTeam from './developmentTeam'
import project from './project'
import status from './status'
import invoice from './invoice'
import projectCost from './projectCost'
import budget from './budget'
import address from './address'
import client from './client'
import tax from './tax'
import summaryProfit from './summaryProfit'
import bank from './bank'
import timeline from './timeline'
import developmentRole from './developmentRole'
import group from './group'
import avatar from './avatar'
import employee from './employee'
import knowledge from './knowledge'
import religions from './religions'
import training from './training'
import dashboard from './dashboard'
import developer from './developer'

export default {
  auth,
  user,
  role,
  projectDetail,
  proposal,
  paymentTerm,
  developmentInfo,
  files,
  developmentTeam,
  project,
  status,
  invoice,
  projectCost,
  budget,
  address,
  client,
  tax,
  summaryProfit,
  bank,
  timeline,
  developmentRole,
  group,
  avatar,
  employee,
  knowledge,
  religions,
  training,
  dashboard,
  developer
}
