import request from './handler'
const save = form => request.post('/clients', form)
const list = () => request.get('/clients/list-clients')
const clientList = params => request.get('/clients/search', { params })
const get = id => request.get(`/clients?id=${id}`)
const projectList = id => request.get(`/clients/projects?id=${id}`)
const deleteClient = id => request.delete(`/clients?id=${id}`)
const update = (id, params) => request.put(`/clients/${id}`, params)

export default {
  save,
  list,
  clientList,
  get,
  projectList,
  deleteClient,
  update
}
