import axios from 'axios'
import router from '@/router'

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`,
  timeout: 50000
})

const refreshInstance = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`,
  timeout: 10000
})

instance.interceptors.request.use(req => {
  if (req.url !== '/auth/login' && req.url !== '/auth/req-reset-password' && req.url !== '/auth/reset-password') {
    req.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  }
  return req
})

const refresh = async (originalRequest) => {
  try {
    const { data } = await refreshInstance.put('/auth/refresh-token', null, {
      params: {
        token: localStorage.getItem('token'),
        refreshToken: localStorage.getItem('refreshToken')
      }
    })
    localStorage.setItem('token', data.token)
    return instance({
      ...originalRequest,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    })
  } catch (error) {
    localStorage.clear()

    router.push({ path: '/login', replace: true })

    return Promise.reject(error)
  }
}

instance.interceptors.response.use(
  response => response,
  error => {
    // Safari can't get the OK response and shows 'Network Error' instead
    const safariUnrealError = error.code === undefined && error.message === 'Network Error'
    const nonSafariUnauthorized = error.response && (error.response.status === 401 || error.response.status === 440)
    // Safari don't have response on error 4xx
    const safariUnauthorized = !error.response
    const refreshRequest = error.config.url.endsWith('/auth/refresh')
    const loginRequest = error.config.url.endsWith('/auth/login')
    const reqResetPassword = error.config.url.endsWith('/auth/req-reset-password')
    const resetPassword = error.config.url.endsWith('/auth/reset-password')
    const internalServerError = error.response.status === 500
    const badRequestError = error.response.status === 400

    if (loginRequest || reqResetPassword || resetPassword) {
      return Promise.reject(error.response || error)
    } else if (safariUnrealError) {
      return Promise.resolve()
    } else if ((nonSafariUnauthorized || safariUnauthorized) && !refreshRequest) {
      return refresh(error.config)
    } else if (internalServerError) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: error.response.statusText })
    } else if (badRequestError) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: error.response.data })
    }

    return Promise.reject(error.response || error)
  }
)

export default instance
