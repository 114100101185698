import request from './handler'

const scrumTeams = async params => {
  const { data } = await request.get('/employees/scrum-teams', { params })
  return data
}
const roles = async params => {
  const { data } = await request.get('/development-roles/roles', { params })
  return data
}
const levels = () => request.get('/levels')
const save = form => request.post('/project-development-teams', form)
const get = id => request.get(`/project-development-teams?projectId=${id}`)
const developers = async params => {
  const { data } = await request.get('/employees/developers', { params })
  return data
}
export default {
  developers,
  scrumTeams,
  roles,
  levels,
  save,
  get
}
